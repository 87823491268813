import React from "react"

import Layout from "../components/layout"

const TermsPage = () => (
    <Layout>
        <div className="terms">
            <h1>Terms of Service</h1>
            <p className="body-text">
                By using the Site, you expressly agree that your identity as established by government documents matches the identity you claim while using the Site and that you are affiliated with the groups you attach to your account. Your status as an eligible party is a material condition of use of the Site and any use of the Site by anyone other than an eligible party shall be considered a material misrepresentation.
            </p>
            <p className="body-text">
                Informational Purposes Only. The information on this Site is for informational purposes only. No information, services, or materials offered by or through this Site shall be construed as or understood to be advice, legal or otherwise. In connection with this statement, please take particular care to read Paragraph 8: Disclaimer of Warranty and Liability.
            </p>
            <p className="body-text">
                Proprietary Rights. All works of authorship, information, content, functional components, and material appearing on or contained in this Site (“Site Materials”) are protected by law, including but not limited to, United States copyright law. Except as explicitly stated on the Site, the entirety of the Site Materials (including, but not limited to, data, illustrations, graphics, audio, video, photographs, pictures, illustrations, recordings, drawings, sketches, artwork, images, text, forms, and look and feel attributes) are CareAdvisors’s copyrighted works, all rights reserved, or the copyrighted works of CareAdvisors’s affiliates, licensors, or suppliers. Removing or altering any copyright notice or any other proprietary notice on any Site Materials is strictly prohibited. Any commercial use of any Site Materials, in whole or in part, without the prior written consent of CareAdvisors, is prohibited. Any reproduction, distribution, performance, display, preparation of derivative works based upon, framing, capturing, harvesting, or collection of, or creating of hypertext or other links or connections to, any Site Materials or any other proprietary information of CareAdvisors, without CareAdvisors’s advance written consent, is prohibited. The sole exception to the prohibitions in this paragraph shall be a limited consent for search engines (such as, for example, www.google.com) to index the Site for the sole purpose of making the Site Materials searchable through their services. All names, trademarks, service marks, symbols, slogans, and logos appearing on the Site are proprietary to CareAdvisors or its affiliates, licensors, or suppliers. Use or misuse of these trademarks is expressly prohibited and may violate federal and state trademark law.
            </p>
            <p className="body-text">
                Third Party Materials, Products and Services. Parties other than CareAdvisors may provide information, products or services on the Site. Use of this Site and the Site Materials and any other material or content on and made available through this Site is entirely at your own risk. CareAdvisors expressly disclaims any and all responsibility for or related to the information, products or services provided by or advertised by these third parties or the transactions you conduct or enter into with these third parties. Additionally, this Site may, from time to time, contain links to other Internet Web sites for the convenience of users in locating information, products, or services that may be of interest. CareAdvisors expressly disclaims any and all responsibility for the content, the accuracy of the information, or quality of products or services provided by or advertised on these third party sites. CareAdvisors reserves the right, in its sole discretion at any time, to deny any request, or rescind any permission granted, to link to the Site and to require termination of any such link to the Site. The availability of links from the Site to third party sites are not, and should not be construed as, an endorsement of or association with these third party sites.
            </p>
            <p className="body-text">
                Your file and personal information, some of which may be protected from disclosure without your consent under various laws and regulations, including pursuant to the Family Education Rights and Privacy Law (FERPA), is being entered in an Internet Accessible Database. Your personal information and/or files may be stored on a web server, transmitted over the Internet, or sent via fax. By using this Site, you are authorizing CareAdvisors and potential service providers to transmit/send your personal information/file information for the purpose of helping you to obtain appropriate services, including authorizing CareAdvisors to send your information to third parties for such purpose. By using this Site, you give your permission for enrollment into programs with services and or training which may be funded by a local, state or federal department or agency. You have been informed that these funding sources may verify service activities reported by CareAdvisors in order to comply with these requirements.
            </p>
        </div>
    </Layout>
)

export default TermsPage